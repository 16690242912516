import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, computed, signal } from '@angular/core';
import { ApiErrorHelper } from '@nx/core/lib/helpers/api-error/api-error.helper';
import {
	Account,
	AccountEditResponse,
	AccountErrorCodes,
} from '@nx/core/lib/interfaces/account.interface';
import {
	LoaderState,
	LoadingStates,
} from '@nx/core/lib/interfaces/loading-states.interface';
import {
	AccountCreateRequestAPI,
	AccountCreateResponseAPI,
	AccountPasswordResetRequestAPI,
	AccountPasswordResetResponseAPI,
	AccountPasswordResetVerifyRequestAPI,
	AccountPasswordResetVerifyResponseAPI,
	AccountPasswordUpdateRequestAPI,
	AccountPasswordUpdateResponseAPI,
	AccountResponseAPI,
	AccountUpdateRequestAPI,
	AccountVerifyRequestAPI,
	AccountVerifyResponseAPI,
} from 'api-interfaces';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	private _createAccountErrorSig = signal<string | undefined>(undefined);
	private _createAccountLoadedSig = signal<boolean>(false);
	private _createAccountLoadingSig = signal<boolean>(false);

	createAccountErrorSig = computed(() => this._createAccountErrorSig());
	createAccountLoadedSig = computed(() => this._createAccountLoadedSig());
	createAccountLoadingSig = computed(() => this._createAccountLoadingSig());

	private _accountStateSig = signal<
		LoaderState<Account, AccountEditResponse>
	>({
		state: LoadingStates.IDLE,
	});
	accountStateComputed = computed(() => this._accountStateSig());

	private _verifyAccountStateSig = signal<
		LoaderState<AccountEditResponse, AccountEditResponse>
	>({
		state: LoadingStates.IDLE,
	});
	verifyAccountStateComputed = computed(() => this._verifyAccountStateSig());

	private _requestPasswordStateSig = signal<
		LoaderState<AccountEditResponse, AccountEditResponse>
	>({
		state: LoadingStates.IDLE,
	});
	requestPasswordStateComputed = computed(() =>
		this._requestPasswordStateSig(),
	);

	private _verifyUpdatePasswordStateSig = signal<
		LoaderState<AccountEditResponse, AccountEditResponse>
	>({
		state: LoadingStates.IDLE,
	});
	verifyUpdatePasswordStateComputed = computed(() =>
		this._verifyUpdatePasswordStateSig(),
	);

	private _updatePasswordStateSig = signal<
		LoaderState<AccountEditResponse, AccountEditResponse>
	>({
		state: LoadingStates.IDLE,
	});
	updatePasswordStateComputed = computed(() =>
		this._updatePasswordStateSig(),
	);

	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	createAccount(
		body: AccountCreateRequestAPI,
	): Observable<AccountEditResponse> {
		this._createAccountLoadingSig.set(true);
		this._createAccountLoadedSig.set(false);

		return this.http
			.post<AccountCreateResponseAPI>(`${this.endpoint}v1/account`, body)
			.pipe(
				map((response) => {
					const result = this.parseCreateAccount(response);
					this._createAccountLoadingSig.set(false);
					this._createAccountLoadedSig.set(true);
					this._createAccountErrorSig.set(result.errorCode);
					return result;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<AccountErrorCodes>(
						errorResponse,
						AccountErrorCodes,
					);
					this._createAccountLoadingSig.set(false);
					this._createAccountLoadedSig.set(false);
					this._createAccountErrorSig.set(error);
					return of({
						success: false,
						errorCode: error,
					} as AccountEditResponse);
				}),
			);
	}

	getAccount(): Observable<Account> {
		this._accountStateSig.set({
			state: LoadingStates.LOADING,
		});

		return this.http
			.get<AccountResponseAPI>(`${this.endpoint}v1/account`, {})
			.pipe(
				map((response) => {
					const data = this.parseAccount(response);
					this._accountStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});
					return data;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<AccountErrorCodes>(
						errorResponse,
						AccountErrorCodes,
					);

					const errorOutput = {
						success: false,
						errorCode: error,
					} as AccountEditResponse;

					this._accountStateSig.set({
						state: LoadingStates.ERROR,
						error: errorOutput,
					});

					return of({
						email: '',
					} as Account);
				}),
			);
	}

	verifyAccount(data: {
		guid: string;
		token: string;
	}): Observable<AccountEditResponse> {
		this._verifyAccountStateSig.set({ state: LoadingStates.LOADING });

		const body: AccountVerifyRequestAPI = {
			guid: data.guid,
			token: data.token,
		};
		return this.http
			.post<AccountVerifyResponseAPI>(
				`${this.endpoint}v1/account/verify`,
				body,
			)
			.pipe(
				map((response) => {
					const data = this.parseVerifyAccount(response);
					this._verifyAccountStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});

					return data;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<AccountErrorCodes>(
						errorResponse,
						AccountErrorCodes,
					);

					const errorOutput = {
						success: false,
						errorCode: error,
					} as AccountEditResponse;

					this._verifyAccountStateSig.set({
						state: LoadingStates.ERROR,
						error: errorOutput,
					});

					return of(errorOutput);
				}),
			);
	}

	requestNewPassword(data: {
		email: string;
	}): Observable<AccountEditResponse> {
		this._requestPasswordStateSig.set({ state: LoadingStates.LOADING });

		const body: AccountPasswordResetRequestAPI = {
			emailaddress: data.email,
		};
		return this.http
			.post<AccountPasswordResetResponseAPI>(
				`${this.endpoint}v1/account/password-reset`,
				body,
			)
			.pipe(
				map((response) => {
					const data = this.parseNewPassword(response);
					this._requestPasswordStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});

					return data;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<AccountErrorCodes>(
						errorResponse,
						AccountErrorCodes,
					);

					const errorOutput = {
						success: false,
						errorCode: error,
					} as AccountEditResponse;

					this._requestPasswordStateSig.set({
						state: LoadingStates.ERROR,
						error: errorOutput,
					});

					return of(errorOutput);
				}),
			);
	}

	verifyUpdatePassword(data: {
		guid: string;
		token: string;
	}): Observable<AccountEditResponse> {
		this._verifyUpdatePasswordStateSig.set({
			state: LoadingStates.LOADING,
		});

		const body: AccountPasswordResetVerifyRequestAPI = {
			guid: data.guid,
			token: data.token,
		};
		return this.http
			.post<AccountPasswordResetVerifyResponseAPI>(
				`${this.endpoint}v1/account/password-reset/verify`,
				body,
			)
			.pipe(
				map((response) => {
					const data = this.parseVerifyPasswordUpdate(response);
					this._verifyUpdatePasswordStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});

					return data;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<AccountErrorCodes>(
						errorResponse,
						AccountErrorCodes,
					);

					const errorOutput = {
						success: false,
						errorCode: error,
					} as AccountEditResponse;

					this._verifyUpdatePasswordStateSig.set({
						state: LoadingStates.ERROR,
						error: errorOutput,
					});

					return of(errorOutput);
				}),
			);
	}

	updatePassword(data: {
		guid: string;
		password: string;
		token: string;
	}): Observable<AccountEditResponse> {
		this._updatePasswordStateSig.set({ state: LoadingStates.LOADING });

		const body: AccountPasswordUpdateRequestAPI = {
			guid: data.guid,
			password: data.password,
			token: data.token,
		};
		return this.http
			.patch<AccountPasswordUpdateResponseAPI>(
				`${this.endpoint}v1/account/password-update`,
				body,
			)
			.pipe(
				map((response) => {
					const data = this.parsePasswordUpdate(response);
					this._updatePasswordStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});

					return data;
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<AccountErrorCodes>(
						errorResponse,
						AccountErrorCodes,
					);

					const errorOutput = {
						success: false,
						errorCode: error,
					} as AccountEditResponse;

					this._updatePasswordStateSig.set({
						state: LoadingStates.ERROR,
						error: errorOutput,
					});

					return of(errorOutput);
				}),
			);
	}

	updateAccount(changes: Partial<Account>): Observable<AccountEditResponse> {
		const initialData = this._accountStateSig().data;
		this._accountStateSig.set({
			state: LoadingStates.LOADING,
			data: {
				...initialData,
				...changes,
			} as Account,
		});

		const body: AccountUpdateRequestAPI = {
			user: {
				...(changes.phone ? { phonenumber: changes.phone } : {}),
				...(changes.firstname ? { firstname: changes.firstname } : {}),
				...(changes.lastname ? { lastname: changes.lastname } : {}),
			},
		};
		return this.http
			.patch<AccountResponseAPI>(`${this.endpoint}v1/account`, body)
			.pipe(
				map((response) => {
					const data = this.parseAccount(response);
					this._accountStateSig.set({
						state: LoadingStates.LOADED,
						data,
					});
					return {
						success: true,
					};
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<AccountErrorCodes>(
						errorResponse,
						AccountErrorCodes,
					);

					const errorOutput = {
						success: false,
						errorCode: error,
					} as AccountEditResponse;

					this._accountStateSig.set({
						state: LoadingStates.ERROR,
						error: errorOutput,
						data: initialData,
					});

					return of(errorOutput);
				}),
			);
	}

	resetAccountState() {
		this._accountStateSig.set({
			state: LoadingStates.IDLE,
		});
	}

	private parseCreateAccount(
		response: AccountCreateResponseAPI,
	): AccountEditResponse {
		return {
			success: !!response.data.success,
		};
	}

	private parseAccount(response: AccountResponseAPI): Account {
		return {
			email: response.data.emailaddress,
			firstname: response.data.firstname,
			guid: response.data.guid,
			isPremium: response.data.deceaseds[0]?.isPremium || false,
			lastname: response.data.lastname,
			phone: response.data.phonenumber || '',
			deceaseds: response.data.deceaseds.map((deceased) => ({
				birthdate: deceased.birthdate,
				deathdate: deceased.deathdate,
				firstname: deceased.firstname,
				guid: deceased.guid,
				lastname: deceased.lastname,
			})),
		};
	}

	private parseVerifyAccount(
		response: AccountVerifyResponseAPI,
	): AccountEditResponse {
		return {
			success: !!response.data.success,
		};
	}

	private parseNewPassword(
		response: AccountPasswordResetResponseAPI,
	): AccountEditResponse {
		return {
			success: !!response.data.success,
		};
	}

	private parseVerifyPasswordUpdate(
		response: AccountPasswordResetVerifyResponseAPI,
	): AccountEditResponse {
		return {
			success: !!response.data.success,
		};
	}

	private parsePasswordUpdate(
		response: AccountPasswordUpdateResponseAPI,
	): AccountEditResponse {
		return {
			success: !!response.data.success,
		};
	}
}
