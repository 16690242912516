export enum AccountErrorCodes {
	ERROR_INVALID_DATE = 'ERROR_INVALID_DATE',
	ACCOUNT_EXISTS = 'ERROR_ACCOUNT_10001',
	DECEASED_EXISTS = 'ERROR_ACCOUNT_10002',
	CAN_NOT_FIND_ACCOUNT = 'ERROR_ACCOUNT_10003',
	ACCOUNT_IS_ALREADY_VERIFIED = 'ERROR_ACCOUNT_10004',
	VERIFICATION_TOKEN_IS_INVALID = 'ERROR_ACCOUNT_10005',
	PASSWORD_RESET_TOKEN_IS_INVALID = 'ERROR_ACCOUNT_10006',
	PREMIUM_CODE_INVALID = 'ERROR_PAYMENT_40001',
	PREMIUM_CODE_EXPIRED = 'ERROR_PAYMENT_40002',
	UNKNOWN = 'ERROR_UNKNOWN',
}

export interface Account {
	deceaseds: Array<AccountDeceased>;
	email: string;
	firstname: string;
	guid: string;
	isPremium: boolean;
	lastname: string;
	phone: string;
}

export interface AccountDeceased {
	birthdate: string;
	deathdate: string;
	firstname: string;
	guid: string;
	lastname: string;
}

export interface AccountEditResponse {
	success: boolean;
	errorCode?: string;
}
